import React from 'react'
import PropTypes, { elementType } from 'prop-types'
import { DefaultSeo } from 'next-seo'
import Head from 'next/head'
import Script from 'next/script'
import '../styles/app.scss'
import SEO from '../src/common/constants/nextSEO'
import { AnalyticsProvider } from '../src/common/analytics'
import { TrustMarkersProvider } from '../src/common/trust-markers'
import { FeatureFlagProvider } from 'src/common/ab-testing/context'
import { LeadIdProvider } from '../src/common/lead-id'
import { useRouter } from 'next/router'
import { parseServerCookies } from 'src/common/serverUtils'
import { ultramarine } from 'src/components/shared/styles'
import getConfig from 'next/config'
import {
  getVisitId,
  VisitIdServerAdapter,
} from '@achieve/cx-event-horizon/browser'
import axios from 'axios'
import { PznTracker } from '../src/common/analytics/Pzn-tacker'

function App({
  Component,
  pageProps: {
    csrfToken,
    recaptchaKey,
    dashboardAuthHost,
    dashboardRedirectHost,
    cookie,
    visitId,
    leadId,
    trustMarkers,
    ...pageProps
  },
}) {
  const analyticsProps = {
    event_origin: 'client',
    page_name: 'FDR Home',
    ...pageProps,
  }

  const { query } = useRouter() //for testing
  const nonce = process.env.nonce
  const hideForTest = query?.hasOwnProperty('performance')
    ? query.performance
    : null //for testing should be removed

  const isCrawler = pageProps.isCrawler || false
  const { publicRuntimeConfig } = getConfig()

  return (
    <div className={`${ultramarine.variable} font-ultramarine`}>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <DefaultSeo {...SEO} />
      <Script id="env-init" nonce={nonce}>
        {`window.env=${JSON.stringify({
          csrfToken,
          recaptchaKey,
          dashboardAuthHost,
          dashboardRedirectHost,
        })}`}
      </Script>
      <LeadIdProvider leadIdFromServerCookies={cookie?.leadId}>
        <FeatureFlagProvider>
          <AnalyticsProvider
            isCrawler={isCrawler}
            pageProps={analyticsProps}
            urlParams={hideForTest}
          >
            <PznTracker />
            <TrustMarkersProvider trustMarkers={trustMarkers}>
              <div id="modals" className="z-200 absolute" />
              <Component {...pageProps} />
            </TrustMarkersProvider>
          </AnalyticsProvider>
        </FeatureFlagProvider>
      </LeadIdProvider>
      <Script
        async
        nonce={nonce}
        strategy="afterInteractive"
        id="insider-profile"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `window.insiderFlow = 1;
window.InsiderQueue = window.InsiderQueue || [];
window.InsiderQueue.push({
  type: "user",
  value: {
    uuid: "${leadId}",
    language: "en_US",
    gdpr_optin: true
  }
});
window.InsiderQueue.push({
  type: "attribute",
  value: {
    visit_id: "${visitId || 'N/A'}",
    profile_origin: "${publicRuntimeConfig.APP_BASE_PATH}"
  }
});
window.InsiderQueue.push({ type: "init" });`,
        }}
      />
      <Script
        async
        id="insider-vendor"
        strategy="afterInteractive"
        crossorigin="anonymous"
        nonce={nonce}
        src={publicRuntimeConfig.INSIDER_SRC}
        type="text/javascript"
      />
      {recaptchaKey ? (
        <Script
          id="recaptcha"
          src={`https://www.google.com/recaptcha/api.js?render=${recaptchaKey}`}
          nonce={nonce}
          defer={true}
        />
      ) : null}
    </div>
  )
}

App.getInitialProps = async ({ ctx }) => {
  const cookie = parseServerCookies(ctx)
  let visitId
  if (ctx?.req && ctx?.res)
    visitId = getVisitId(new VisitIdServerAdapter(ctx.req, ctx.res))
  const leadId = cookie?.leadId
  // Handle errors during the API request with try-catch block
  let trustMarkers = []
  try {
    const response = await axios.get(
      `${process.env.APP_BASE_PATH}/api/get-trust-markers`
    )
    trustMarkers = response?.data?.trustMarkers || []
  } catch (error) {
    console.error('Error fetching trust markers:', error)
    trustMarkers = [] // Fallback to empty array if request fails
  }

  return {
    pageProps: {
      cookie,
      visitId,
      leadId,
      trustMarkers,
    },
  }
}

App.defaultProps = {
  pageProps: {},
  router: {},
}
App.propTypes = {
  Component: elementType.isRequired,
  pageProps: PropTypes.object,
  router: PropTypes.object,
}

export default App
